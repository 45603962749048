@tailwind base;
@tailwind components;
@tailwind utilities;



.App{
  background-color: #9ddfff;
}

#tsparticles{
  position: absolute;
  z-index: 0;
}

/* color */
.bg-primary {
    background: #0F172A;
}

.bg-secondery {
    background: #fff;
}

.bg-btn {
  background-color: #ff0200;
}
.bg-btn:hover {
  background-color: #920c0a;
}
.bg-accent {
    background: #7477FF;
}

.text-accent {
    color: #9ddfff;
} 

nav li a {
    @apply px-4 py-5 text-lg;
}

nav li a:hover {
    color: #9ddfff;
}

/* hero */
h1 {
    font-family: 'Tilt Warp', cursive;
    line-height: 1.5 !important;
}

.hero-text{
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.about-text{
  font-family: 'Montserrat', sans-serif;
}


.mobile-nav {
  display: block;
  position: fixed;
  z-index: 20;
  top: 45px;
  padding: 2rem 0;
  width: 100%;
  height: 100%;
  background-color: #2D3748;
  transition: all 0.5s ease-in-out;
}

@media (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Tilt Warp', cursive; */